<template>
  <div class="flex align-items-center justify-content-center">
    <div style="width: 100%; max-width: 1600px">
      <div class="flex flex-column">
        <div class="flex align-items-center justify-content-center">
          <div></div>
        </div>
        <div
          class="flex align-items-center justify-content-center"
          style="min-width: 300px"
        >
          <div class="flex flex-column">
            <Accordion :activeIndex="0" style="min-width: 300px">
              <AccordionTab header="Anzahl Ligen pro Ligagruppe">
                Anzahl Oberligen ({{ olCount }})
                <Rating
                  v-model="olCount"
                  :stars="1"
                  :cancel="false"
                  style="margin-bottom: 20px"
                />
                Anzahl Bezirksoberligen ({{ bzoCount }})
                <Rating
                  v-model="bzoCount"
                  :stars="5"
                  :cancel="false"
                  style="margin-bottom: 20px"
                />
                Anzahl Bezirksligen ({{ bzCount }})
                <Rating
                  v-model="bzCount"
                  :stars="5"
                  :cancel="false"
                  style="margin-bottom: 20px"
                />
                Anzahl Kreisbezirksligen ({{ kbzCount }})
                <Rating
                  v-model="kbzCount"
                  :stars="5"
                  :cancel="false"
                  style="margin-bottom: 20px"
                />
                Anzahl Kreisligen ({{ klCount }})
                <Rating
                  v-model="klCount"
                  :stars="8"
                  :cancel="false"
                  style="margin-bottom: 20px"
                />
              </AccordionTab>
              <AccordionTab header="Auf-/Absteiger pro Ligagruppe">
                <i class="pi pi-arrow-down" style="font-size: 22px">
                  Oberliga ({{ olRelegatedCount }})</i
                >
                <Slider
                  v-model="olRelegatedCount"
                  :max="5"
                  style="margin: 5px 10px 20px 10px"
                />
                <i class="pi pi-arrow-up" style="font-size: 22px">
                  Bezirksoberliga ({{ bzoPromotedCount }})</i
                >
                <Slider
                  v-model="bzoPromotedCount"
                  :max="10"
                  style="margin: 5px 10px 20px 10px"
                />
                <i class="pi pi-arrow-down" style="font-size: 22px">
                  Bezirksoberliga ({{ bzoRelegatedCount }})</i
                >
                <Slider
                  v-model="bzoRelegatedCount"
                  :max="10"
                  style="margin: 5px 10px 20px 10px"
                />
                <i class="pi pi-arrow-up" style="font-size: 22px">
                  Bezirksliga ({{ bzPromotedCount }})</i
                >
                <Slider
                  v-model="bzPromotedCount"
                  :max="20"
                  style="margin: 5px 10px 20px 10px"
                />
                <i class="pi pi-arrow-down" style="font-size: 22px">
                  Bezirksliga ({{ bzRelegatedCount }})</i
                >
                <Slider
                  v-model="bzRelegatedCount"
                  :max="20"
                  style="margin: 5px 10px 20px 10px"
                />
                <i class="pi pi-arrow-up" style="font-size: 22px">
                  Kreisbezirksliga ({{ kbzPromotedCount }})</i
                >
                <Slider
                  v-model="kbzPromotedCount"
                  :max="20"
                  style="margin: 5px 10px 20px 10px"
                />
                <i class="pi pi-arrow-down" style="font-size: 22px">
                  Kreisbezirksliga ({{ kbzRelegatedCount }})</i
                >
                <Slider
                  v-model="kbzRelegatedCount"
                  :max="20"
                  style="margin: 5px 10px 20px 10px"
                />
                <i class="pi pi-arrow-up" style="font-size: 22px">
                  Kreisliga ({{ klPromotedCount }})</i
                >
                <Slider
                  v-model="klPromotedCount"
                  :max="20"
                  style="margin: 5px 10px 20px 10px"
                />
              </AccordionTab>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Rating from "primevue/rating";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Slider from "primevue/slider";

export default {
  name: "Test",
  components: {
    Rating,
    Accordion,
    AccordionTab,
    Slider,
  },
  created() {},
  data() {
    return {
      olCount: 1,
      olRelegatedCount: 1,
      bzoCount: 1,
      bzoPromotedCount: 1,
      bzoRelegatedCount: 1,
      bzCount: 2,
      bzPromotedCount: 1,
      bzRelegatedCount: 1,
      kbzCount: 2,
      kbzPromotedCount: 1,
      kbzRelegatedCount: 1,
      klCount: 5,
      klPromotedCount: 1,
    };
  },
  props: {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
